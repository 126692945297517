[data-color-mode="dark"][data-dark-theme="dark"],
[data-color-mode="dark"][data-dark-theme="dark"] ::backdrop,
[data-color-mode="auto"][data-light-theme="dark"],
[data-color-mode="auto"][data-light-theme="dark"] ::backdrop {
  --topicTag-borderColor: #00000000;
  --highlight-neutral-bgColor: #d2992266;
  --page-header-bgColor: #0d1117;
  --diffBlob-addition-fgColor-text: #f0f6fc;
  --diffBlob-addition-fgColor-num: #f0f6fc;
  --diffBlob-addition-bgColor-num: #3fb9504d;
  --diffBlob-addition-bgColor-line: #2ea04326;
  --diffBlob-addition-bgColor-word: #2ea04366;
  --diffBlob-deletion-fgColor-text: #f0f6fc;
  --diffBlob-deletion-fgColor-num: #f0f6fc;
  --diffBlob-deletion-bgColor-num: #f851494d;
  --diffBlob-deletion-bgColor-line: #f8514926;
  --diffBlob-deletion-bgColor-word: #f8514966;
  --diffBlob-hunk-bgColor-num: #388bfd66;
  --diffBlob-expander-iconColor: #9198a1;
  --codeMirror-fgColor: #f0f6fc;
  --codeMirror-bgColor: #0d1117;
  --codeMirror-gutters-bgColor: #0d1117;
  --codeMirror-gutterMarker-fgColor-default: #0d1117;
  --codeMirror-gutterMarker-fgColor-muted: #9198a1;
  --codeMirror-lineNumber-fgColor: #9198a1;
  --codeMirror-cursor-fgColor: #f0f6fc;
  --codeMirror-selection-bgColor: #388bfd66;
  --codeMirror-activeline-bgColor: #656c7633;
  --codeMirror-matchingBracket-fgColor: #f0f6fc;
  --codeMirror-lines-bgColor: #0d1117;
  --codeMirror-syntax-fgColor-comment: #656c76;
  --codeMirror-syntax-fgColor-constant: #79c0ff;
  --codeMirror-syntax-fgColor-entity: #d2a8ff;
  --codeMirror-syntax-fgColor-keyword: #ff7b72;
  --codeMirror-syntax-fgColor-storage: #ff7b72;
  --codeMirror-syntax-fgColor-string: #a5d6ff;
  --codeMirror-syntax-fgColor-support: #79c0ff;
  --codeMirror-syntax-fgColor-variable: #ffa657;
  --header-fgColor-default: #ffffffb3;
  --header-fgColor-logo: #f0f6fc;
  --header-bgColor: #151b23f2;
  --header-borderColor-divider: #656c76;
  --headerSearch-bgColor: #0d1117;
  --headerSearch-borderColor: #2a313c;
  --data-blue-color-emphasis: #0576ff;
  --data-blue-color-muted: #001a47;
  --data-auburn-color-emphasis: #a86f6b;
  --data-auburn-color-muted: #271817;
  --data-orange-color-emphasis: #984b10;
  --data-orange-color-muted: #311708;
  --data-yellow-color-emphasis: #895906;
  --data-yellow-color-muted: #2e1a00;
  --data-green-color-emphasis: #2f6f37;
  --data-green-color-muted: #122117;
  --data-teal-color-emphasis: #106c70;
  --data-teal-color-muted: #041f25;
  --data-purple-color-emphasis: #975bf1;
  --data-purple-color-muted: #211047;
  --data-pink-color-emphasis: #d34591;
  --data-pink-color-muted: #2d1524;
  --data-red-color-emphasis: #eb3342;
  --data-red-color-muted: #3c0614;
  --data-gray-color-emphasis: #576270;
  --data-gray-color-muted: #1c1c1c;
  --display-blue-bgColor-muted: #001a47;
  --display-blue-bgColor-emphasis: #005bd1;
  --display-blue-fgColor: #4da0ff;
  --display-blue-borderColor-muted: #002766;
  --display-blue-borderColor-emphasis: #0576ff;
  --display-green-bgColor-muted: #122117;
  --display-green-bgColor-emphasis: #2f6f37;
  --display-green-fgColor: #41b445;
  --display-green-borderColor-muted: #182f1f;
  --display-green-borderColor-emphasis: #388f3f;
  --display-orange-bgColor-muted: #311708;
  --display-orange-bgColor-emphasis: #984b10;
  --display-orange-fgColor: #ed8326;
  --display-orange-borderColor-muted: #43200a;
  --display-orange-borderColor-emphasis: #c46212;
  --display-purple-bgColor-muted: #211047;
  --display-purple-bgColor-emphasis: #7730e8;
  --display-purple-fgColor: #b687f7;
  --display-purple-borderColor-muted: #31146b;
  --display-purple-borderColor-emphasis: #975bf1;
  --display-plum-bgColor-muted: #2a0e3f;
  --display-plum-bgColor-emphasis: #9518d8;
  --display-plum-fgColor: #d07ef7;
  --display-plum-borderColor-muted: #40125e;
  --display-plum-borderColor-emphasis: #b643ef;
  --display-red-bgColor-muted: #3c0614;
  --display-red-bgColor-emphasis: #c31328;
  --display-red-fgColor: #f27d83;
  --display-red-borderColor-muted: #58091a;
  --display-red-borderColor-emphasis: #eb3342;
  --display-coral-bgColor-muted: #3c0614;
  --display-coral-bgColor-emphasis: #c31328;
  --display-coral-fgColor: #f27d83;
  --display-coral-borderColor-muted: #58091a;
  --display-coral-borderColor-emphasis: #eb3342;
  --display-yellow-bgColor-muted: #2e1a00;
  --display-yellow-bgColor-emphasis: #895906;
  --display-yellow-fgColor: #d3910d;
  --display-yellow-borderColor-muted: #3d2401;
  --display-yellow-borderColor-emphasis: #aa7109;
  --display-gray-bgColor-muted: #1c1c1c;
  --display-gray-bgColor-emphasis: #576270;
  --display-gray-fgColor: #92a1b5;
  --display-gray-borderColor-muted: #2a2b2d;
  --display-gray-borderColor-emphasis: #6e7f96;
  --display-auburn-bgColor-muted: #271817;
  --display-auburn-bgColor-emphasis: #87534f;
  --display-auburn-fgColor: #bf9592;
  --display-auburn-borderColor-muted: #3a2422;
  --display-auburn-borderColor-emphasis: #a86f6b;
  --display-brown-bgColor-muted: #241c14;
  --display-brown-bgColor-emphasis: #755e3e;
  --display-brown-fgColor: #b69a6d;
  --display-brown-borderColor-muted: #342a1d;
  --display-brown-borderColor-emphasis: #94774c;
  --display-lemon-bgColor-muted: #291d00;
  --display-lemon-bgColor-emphasis: #786008;
  --display-lemon-fgColor: #ba9b12;
  --display-lemon-borderColor-muted: #372901;
  --display-lemon-borderColor-emphasis: #977b0c;
  --display-olive-bgColor-muted: #171e0b;
  --display-olive-bgColor-emphasis: #5e681d;
  --display-olive-fgColor: #a2a626;
  --display-olive-borderColor-muted: #252d10;
  --display-olive-borderColor-emphasis: #7a8321;
  --display-lime-bgColor-muted: #141f0f;
  --display-lime-bgColor-emphasis: #496c28;
  --display-lime-fgColor: #7dae37;
  --display-lime-borderColor-muted: #1f3116;
  --display-lime-borderColor-emphasis: #5f892f;
  --display-pine-bgColor-muted: #082119;
  --display-pine-bgColor-emphasis: #14714c;
  --display-pine-fgColor: #1bb673;
  --display-pine-borderColor-muted: #0b3224;
  --display-pine-borderColor-emphasis: #18915e;
  --display-teal-bgColor-muted: #041f25;
  --display-teal-bgColor-emphasis: #106c70;
  --display-teal-fgColor: #1cb0ab;
  --display-teal-borderColor-muted: #073036;
  --display-teal-borderColor-emphasis: #158a8a;
  --display-cyan-bgColor-muted: #001f29;
  --display-cyan-bgColor-emphasis: #036a8c;
  --display-cyan-fgColor: #07ace4;
  --display-cyan-borderColor-muted: #002e3d;
  --display-cyan-borderColor-emphasis: #0587b3;
  --display-indigo-bgColor-muted: #1b183f;
  --display-indigo-bgColor-emphasis: #514ed4;
  --display-indigo-fgColor: #9899ec;
  --display-indigo-borderColor-muted: #25215f;
  --display-indigo-borderColor-emphasis: #7070e1;
  --display-pink-bgColor-muted: #2d1524;
  --display-pink-bgColor-emphasis: #ac2f74;
  --display-pink-fgColor: #e57bb2;
  --display-pink-borderColor-muted: #451c35;
  --display-pink-borderColor-emphasis: #d34591;
  --avatar-bgColor: #ffffff1a;
  --avatar-borderColor: #ffffff26;
  --avatar-shadow: 0px 0px 0px 2px #0d1117;
  --avatarStack-fade-bgColor-default: #3d444d;
  --avatarStack-fade-bgColor-muted: #2a313c;
  --control-bgColor-rest: #212830;
  --control-bgColor-hover: #262c36;
  --control-bgColor-active: #2a313c;
  --control-bgColor-disabled: #212830;
  --control-bgColor-selected: #212830;
  --control-fgColor-rest: #f0f6fc;
  --control-fgColor-placeholder: #9198a1;
  --control-fgColor-disabled: #656c7699;
  --control-borderColor-rest: #3d444d;
  --control-borderColor-emphasis: #656c76;
  --control-borderColor-disabled: #656c761a;
  --control-borderColor-selected: #f0f6fc;
  --control-borderColor-success: #238636;
  --control-borderColor-danger: #da3633;
  --control-borderColor-warning: #9e6a03;
  --control-iconColor-rest: #9198a1;
  --control-transparent-bgColor-rest: #00000000;
  --control-transparent-bgColor-hover: #656c7633;
  --control-transparent-bgColor-active: #656c7640;
  --control-transparent-bgColor-disabled: #212830;
  --control-transparent-bgColor-selected: #656c761a;
  --control-transparent-borderColor-rest: #00000000;
  --control-transparent-borderColor-hover: #00000000;
  --control-transparent-borderColor-active: #00000000;
  --control-danger-fgColor-rest: #f85149;
  --control-danger-fgColor-hover: #ff7b72;
  --control-danger-bgColor-hover: #f851491a;
  --control-danger-bgColor-active: #f8514966;
  --control-checked-bgColor-rest: #1f6feb;
  --control-checked-bgColor-hover: #2a7aef;
  --control-checked-bgColor-active: #3685f3;
  --control-checked-bgColor-disabled: #656c7699;
  --control-checked-fgColor-rest: #ffffff;
  --control-checked-fgColor-disabled: #010409;
  --control-checked-borderColor-rest: #1f6feb;
  --control-checked-borderColor-hover: #2a7aef;
  --control-checked-borderColor-active: #3685f3;
  --control-checked-borderColor-disabled: #656c7699;
  --controlTrack-bgColor-rest: #262c36;
  --controlTrack-bgColor-hover: #2a313c;
  --controlTrack-bgColor-active: #2f3742;
  --controlTrack-bgColor-disabled: #656c7699;
  --controlTrack-fgColor-rest: #9198a1;
  --controlTrack-fgColor-disabled: #ffffff;
  --controlTrack-borderColor-rest: #3d444d;
  --controlTrack-borderColor-disabled: #656c7699;
  --controlKnob-bgColor-rest: #010409;
  --controlKnob-bgColor-disabled: #212830;
  --controlKnob-bgColor-checked: #ffffff;
  --controlKnob-borderColor-rest: #656c76;
  --controlKnob-borderColor-disabled: #212830;
  --controlKnob-borderColor-checked: #1f6feb;
  --counter-borderColor: #00000000;
  --counter-bgColor-muted: #656c7633;
  --counter-bgColor-emphasis: #656c76;
  --button-default-fgColor-rest: #f0f6fc;
  --button-default-bgColor-rest: #212830;
  --button-default-bgColor-hover: #262c36;
  --button-default-bgColor-active: #2a313c;
  --button-default-bgColor-selected: #2a313c;
  --button-default-bgColor-disabled: #212830;
  --button-default-borderColor-rest: #3d444d;
  --button-default-borderColor-hover: #3d444d;
  --button-default-borderColor-active: #3d444d;
  --button-default-borderColor-disabled: #656c761a;
  --button-default-shadow-resting: 0px 0px 0px 0px #000000;
  --button-primary-fgColor-rest: #ffffff;
  --button-primary-fgColor-disabled: #ffffff66;
  --button-primary-iconColor-rest: #ffffff;
  --button-primary-bgColor-rest: #238636;
  --button-primary-bgColor-hover: #29903b;
  --button-primary-bgColor-active: #2e9a40;
  --button-primary-bgColor-disabled: #105823;
  --button-primary-borderColor-rest: #ffffff1a;
  --button-primary-borderColor-hover: #ffffff1a;
  --button-primary-borderColor-active: #ffffff1a;
  --button-primary-borderColor-disabled: #105823;
  --button-primary-shadow-selected: 0px 0px 0px 0px #000000;
  --button-invisible-fgColor-rest: #f0f6fc;
  --button-invisible-fgColor-hover: #f0f6fc;
  --button-invisible-fgColor-disabled: #656c7699;
  --button-invisible-iconColor-rest: #9198a1;
  --button-invisible-iconColor-hover: #9198a1;
  --button-invisible-iconColor-disabled: #656c7699;
  --button-invisible-bgColor-rest: #00000000;
  --button-invisible-bgColor-hover: #656c7633;
  --button-invisible-bgColor-active: #656c7640;
  --button-invisible-bgColor-disabled: #212830;
  --button-invisible-borderColor-rest: #00000000;
  --button-invisible-borderColor-hover: #00000000;
  --button-invisible-borderColor-disabled: #656c761a;
  --button-outline-fgColor-rest: #388bfd;
  --button-outline-fgColor-hover: #58a6ff;
  --button-outline-fgColor-active: #ffffff;
  --button-outline-fgColor-disabled: #4493f880;
  --button-outline-bgColor-rest: #f0f6fc;
  --button-outline-bgColor-hover: #262c36;
  --button-outline-bgColor-active: #0d419d;
  --button-outline-bgColor-disabled: #212830;
  --button-outline-borderColor-hover: #3d444d;
  --button-outline-borderColor-selected: #3d444d;
  --button-outline-shadow-selected: 0px 0px 0px 0px #000000;
  --button-danger-fgColor-rest: #fa5e55;
  --button-danger-fgColor-hover: #ffffff;
  --button-danger-fgColor-active: #ffffff;
  --button-danger-fgColor-disabled: #f8514980;
  --button-danger-iconColor-rest: #fa5e55;
  --button-danger-iconColor-hover: #ffffff;
  --button-danger-bgColor-rest: #212830;
  --button-danger-bgColor-hover: #b62324;
  --button-danger-bgColor-active: #d03533;
  --button-danger-bgColor-disabled: #212830;
  --button-danger-borderColor-rest: #3d444d;
  --button-danger-borderColor-hover: #ffffff1a;
  --button-danger-borderColor-active: #ffffff1a;
  --button-danger-shadow-selected: 0px 0px 0px 0px #000000;
  --button-inactive-fgColor: #9198a1;
  --button-inactive-bgColor: #262c36;
  --button-star-iconColor: #e3b341;
  --buttonCounter-default-bgColor-rest: #2f3742;
  --buttonCounter-invisible-bgColor-rest: #656c7633;
  --buttonCounter-primary-bgColor-rest: #04260f33;
  --buttonCounter-outline-bgColor-rest: #051d4d33;
  --buttonCounter-outline-bgColor-hover: #051d4d33;
  --buttonCounter-outline-bgColor-disabled: #1f6feb0d;
  --buttonCounter-outline-fgColor-rest: #388bfd;
  --buttonCounter-outline-fgColor-hover: #58a6ff;
  --buttonCounter-outline-fgColor-disabled: #4493f880;
  --buttonCounter-danger-bgColor-hover: #ffffff33;
  --buttonCounter-danger-bgColor-disabled: #da36330d;
  --buttonCounter-danger-bgColor-rest: #49020233;
  --buttonCounter-danger-fgColor-rest: #f85149;
  --buttonCounter-danger-fgColor-hover: #ffffff;
  --buttonCounter-danger-fgColor-disabled: #f8514980;
  --reactionButton-selected-bgColor-rest: #388bfd33;
  --reactionButton-selected-bgColor-hover: #3a8cfd5c;
  --reactionButton-selected-fgColor-rest: #4493f8;
  --reactionButton-selected-fgColor-hover: #79c0ff;
  --focus-outlineColor: #1f6feb;
  --menu-bgColor-active: #151b23;
  --overlay-bgColor: #151b23;
  --overlay-borderColor: #3d444db3;
  --overlay-backdrop-bgColor: #21283066;
  --selectMenu-borderColor: #3d444d;
  --selectMenu-bgColor-active: #0c2d6b;
  --sideNav-bgColor-selected: #212830;
  --skeletonLoader-bgColor: #151b23;
  --timelineBadge-bgColor: #212830;
  --treeViewItem-leadingVisual-iconColor-rest: #9198a1;
  --underlineNav-borderColor-active: #f78166;
  --underlineNav-borderColor-hover: #3d444db3;
  --underlineNav-iconColor-rest: #9198a1;
  --selection-bgColor: #1f6febb3;
  --card-bgColor: #151b23;
  --label-green-bgColor-rest: #122117;
  --label-green-bgColor-hover: #182f1f;
  --label-green-bgColor-active: #214529;
  --label-green-fgColor-rest: #41b445;
  --label-green-fgColor-hover: #46c144;
  --label-green-fgColor-active: #75d36f;
  --label-orange-bgColor-rest: #311708;
  --label-orange-bgColor-hover: #43200a;
  --label-orange-bgColor-active: #632f0d;
  --label-orange-fgColor-rest: #ed8326;
  --label-orange-fgColor-hover: #f1933b;
  --label-orange-fgColor-active: #f6b06a;
  --label-purple-bgColor-rest: #211047;
  --label-purple-bgColor-hover: #31146b;
  --label-purple-bgColor-active: #481a9e;
  --label-purple-fgColor-rest: #b687f7;
  --label-purple-fgColor-hover: #c398fb;
  --label-purple-fgColor-active: #d2affd;
  --label-red-bgColor-rest: #3c0614;
  --label-red-bgColor-hover: #58091a;
  --label-red-bgColor-active: #790c20;
  --label-red-fgColor-rest: #f27d83;
  --label-red-fgColor-hover: #f48b8d;
  --label-red-fgColor-active: #f7adab;
  --label-yellow-bgColor-rest: #2e1a00;
  --label-yellow-bgColor-hover: #3d2401;
  --label-yellow-bgColor-active: #5a3702;
  --label-yellow-fgColor-rest: #d3910d;
  --label-yellow-fgColor-hover: #df9e11;
  --label-yellow-fgColor-active: #edb431;
  --label-gray-bgColor-rest: #1c1c1c;
  --label-gray-bgColor-hover: #2a2b2d;
  --label-gray-bgColor-active: #393d41;
  --label-gray-fgColor-rest: #92a1b5;
  --label-gray-fgColor-hover: #9babbf;
  --label-gray-fgColor-active: #b3c0d1;
  --label-auburn-bgColor-rest: #271817;
  --label-auburn-bgColor-hover: #3a2422;
  --label-auburn-bgColor-active: #543331;
  --label-auburn-fgColor-rest: #bf9592;
  --label-auburn-fgColor-hover: #c6a19f;
  --label-auburn-fgColor-active: #d4b7b5;
  --label-brown-bgColor-rest: #241c14;
  --label-brown-bgColor-hover: #342a1d;
  --label-brown-bgColor-active: #483a28;
  --label-brown-fgColor-rest: #b69a6d;
  --label-brown-fgColor-hover: #bfa77d;
  --label-brown-fgColor-active: #cdbb98;
  --label-lemon-bgColor-rest: #291d00;
  --label-lemon-bgColor-hover: #372901;
  --label-lemon-bgColor-active: #4f3c02;
  --label-lemon-fgColor-rest: #ba9b12;
  --label-lemon-fgColor-hover: #c4a717;
  --label-lemon-fgColor-active: #d7bc1d;
  --label-olive-bgColor-rest: #171e0b;
  --label-olive-bgColor-hover: #252d10;
  --label-olive-bgColor-active: #374115;
  --label-olive-fgColor-rest: #a2a626;
  --label-olive-fgColor-hover: #b2af24;
  --label-olive-fgColor-active: #cbc025;
  --label-lime-bgColor-rest: #141f0f;
  --label-lime-bgColor-hover: #1f3116;
  --label-lime-bgColor-active: #2c441d;
  --label-lime-fgColor-rest: #7dae37;
  --label-lime-fgColor-hover: #89ba36;
  --label-lime-fgColor-active: #9fcc3e;
  --label-pine-bgColor-rest: #082119;
  --label-pine-bgColor-hover: #0b3224;
  --label-pine-bgColor-active: #0e4430;
  --label-pine-fgColor-rest: #1bb673;
  --label-pine-fgColor-hover: #1ac176;
  --label-pine-fgColor-active: #1bda81;
  --label-teal-bgColor-rest: #041f25;
  --label-teal-bgColor-hover: #073036;
  --label-teal-bgColor-active: #0a464d;
  --label-teal-fgColor-rest: #1cb0ab;
  --label-teal-fgColor-hover: #1fbdb2;
  --label-teal-fgColor-active: #24d6c4;
  --label-cyan-bgColor-rest: #001f29;
  --label-cyan-bgColor-hover: #002e3d;
  --label-cyan-bgColor-active: #014156;
  --label-cyan-fgColor-rest: #07ace4;
  --label-cyan-fgColor-hover: #09b7f1;
  --label-cyan-fgColor-active: #45cbf7;
  --label-indigo-bgColor-rest: #1b183f;
  --label-indigo-bgColor-hover: #25215f;
  --label-indigo-bgColor-active: #312c90;
  --label-indigo-fgColor-rest: #9899ec;
  --label-indigo-fgColor-hover: #a2a5f1;
  --label-indigo-fgColor-active: #b7baf6;
  --label-blue-bgColor-rest: #001a47;
  --label-blue-bgColor-hover: #002766;
  --label-blue-bgColor-active: #00378a;
  --label-blue-fgColor-rest: #4da0ff;
  --label-blue-fgColor-hover: #61adff;
  --label-blue-fgColor-active: #85c2ff;
  --label-plum-bgColor-rest: #2a0e3f;
  --label-plum-bgColor-hover: #40125e;
  --label-plum-bgColor-active: #5c1688;
  --label-plum-fgColor-rest: #d07ef7;
  --label-plum-fgColor-hover: #d889fa;
  --label-plum-fgColor-active: #e4a5fd;
  --label-pink-bgColor-rest: #2d1524;
  --label-pink-bgColor-hover: #451c35;
  --label-pink-bgColor-active: #65244a;
  --label-pink-fgColor-rest: #e57bb2;
  --label-pink-fgColor-hover: #ec8dbd;
  --label-pink-fgColor-active: #f4a9cd;
  --label-coral-bgColor-rest: #351008;
  --label-coral-bgColor-hover: #51180b;
  --label-coral-bgColor-active: #72220d;
  --label-coral-fgColor-rest: #f7794b;
  --label-coral-fgColor-hover: #fa8c61;
  --label-coral-fgColor-active: #fdaa86;
  --tooltip-bgColor: #3d444d;
  --tooltip-fgColor: #ffffff;
  --fgColor-default: #f0f6fc;
  --fgColor-muted: #9198a1;
  --fgColor-onEmphasis: #ffffff;
  --fgColor-onInverse: #010409;
  --fgColor-white: #ffffff;
  --fgColor-black: #010409;
  --fgColor-disabled: #656c7699;
  --fgColor-link: #4493f8;
  --fgColor-neutral: #9198a1;
  --fgColor-accent: #4493f8;
  --fgColor-success: #3fb950;
  --fgColor-open: #3fb950;
  --fgColor-attention: #d29922;
  --fgColor-severe: #db6d28;
  --fgColor-danger: #f85149;
  --fgColor-closed: #f85149;
  --fgColor-done: #ab7df8;
  --fgColor-upsell: #ab7df8;
  --fgColor-sponsors: #db61a2;
  --bgColor-default: #0d1117;
  --bgColor-muted: #151b23;
  --bgColor-inset: #010409;
  --bgColor-emphasis: #3d444d;
  --bgColor-inverse: #ffffff;
  --bgColor-white: #ffffff;
  --bgColor-black: #010409;
  --bgColor-disabled: #212830;
  --bgColor-transparent: #00000000;
  --bgColor-neutral-muted: #656c7633;
  --bgColor-neutral-emphasis: #656c76;
  --bgColor-accent-muted: #388bfd1a;
  --bgColor-accent-emphasis: #1f6feb;
  --bgColor-success-muted: #2ea04326;
  --bgColor-success-emphasis: #238636;
  --bgColor-open-muted: #2ea04326;
  --bgColor-open-emphasis: #238636;
  --bgColor-attention-muted: #bb800926;
  --bgColor-attention-emphasis: #9e6a03;
  --bgColor-severe-muted: #db6d281a;
  --bgColor-severe-emphasis: #bd561d;
  --bgColor-danger-muted: #f851491a;
  --bgColor-danger-emphasis: #da3633;
  --bgColor-closed-muted: #f851491a;
  --bgColor-closed-emphasis: #da3633;
  --bgColor-done-muted: #ab7df826;
  --bgColor-done-emphasis: #8957e5;
  --bgColor-upsell-muted: #ab7df826;
  --bgColor-upsell-emphasis: #8957e5;
  --bgColor-sponsors-muted: #db61a21a;
  --bgColor-sponsors-emphasis: #bf4b8a;
  --borderColor-default: #3d444d;
  --borderColor-muted: #3d444db3;
  --borderColor-emphasis: #656c76;
  --borderColor-disabled: #656c761a;
  --borderColor-transparent: #00000000;
  --borderColor-translucent: #ffffff26;
  --borderColor-neutral-muted: #3d444db3;
  --borderColor-neutral-emphasis: #656c76;
  --borderColor-accent-muted: #388bfd66;
  --borderColor-accent-emphasis: #1f6feb;
  --borderColor-success-muted: #2ea04366;
  --borderColor-success-emphasis: #238636;
  --borderColor-open-muted: #2ea04366;
  --borderColor-open-emphasis: #238636;
  --borderColor-attention-muted: #bb800966;
  --borderColor-attention-emphasis: #9e6a03;
  --borderColor-severe-muted: #db6d2866;
  --borderColor-severe-emphasis: #bd561d;
  --borderColor-danger-muted: #f8514966;
  --borderColor-danger-emphasis: #da3633;
  --borderColor-closed-muted: #f8514966;
  --borderColor-closed-emphasis: #da3633;
  --borderColor-done-muted: #ab7df866;
  --borderColor-done-emphasis: #8957e5;
  --borderColor-upsell-muted: #ab7df866;
  --borderColor-upsell-emphasis: #8957e5;
  --borderColor-sponsors-muted: #db61a266;
  --borderColor-sponsors-emphasis: #bf4b8a;
  --color-ansi-black: #2f3742;
  --color-ansi-black-bright: #656c76;
  --color-ansi-white: #f0f6fc;
  --color-ansi-white-bright: #ffffff;
  --color-ansi-gray: #656c76;
  --color-ansi-red: #ff7b72;
  --color-ansi-red-bright: #ffa198;
  --color-ansi-green: #3fb950;
  --color-ansi-green-bright: #56d364;
  --color-ansi-yellow: #d29922;
  --color-ansi-yellow-bright: #e3b341;
  --color-ansi-blue: #58a6ff;
  --color-ansi-blue-bright: #79c0ff;
  --color-ansi-magenta: #be8fff;
  --color-ansi-magenta-bright: #d2a8ff;
  --color-ansi-cyan: #39c5cf;
  --color-ansi-cyan-bright: #56d4dd;
  --color-prettylights-syntax-comment: #9198a1;
  --color-prettylights-syntax-constant: #79c0ff;
  --color-prettylights-syntax-constant-other-reference-link: #a5d6ff;
  --color-prettylights-syntax-entity: #d2a8ff;
  --color-prettylights-syntax-storage-modifier-import: #f0f6fc;
  --color-prettylights-syntax-entity-tag: #7ee787;
  --color-prettylights-syntax-keyword: #ff7b72;
  --color-prettylights-syntax-string: #a5d6ff;
  --color-prettylights-syntax-variable: #ffa657;
  --color-prettylights-syntax-brackethighlighter-unmatched: #f85149;
  --color-prettylights-syntax-brackethighlighter-angle: #9198a1;
  --color-prettylights-syntax-invalid-illegal-text: #f0f6fc;
  --color-prettylights-syntax-invalid-illegal-bg: #8e1519;
  --color-prettylights-syntax-carriage-return-text: #f0f6fc;
  --color-prettylights-syntax-carriage-return-bg: #b62324;
  --color-prettylights-syntax-string-regexp: #7ee787;
  --color-prettylights-syntax-markup-list: #f2cc60;
  --color-prettylights-syntax-markup-heading: #1f6feb;
  --color-prettylights-syntax-markup-italic: #f0f6fc;
  --color-prettylights-syntax-markup-bold: #f0f6fc;
  --color-prettylights-syntax-markup-deleted-text: #ffdcd7;
  --color-prettylights-syntax-markup-deleted-bg: #67060c;
  --color-prettylights-syntax-markup-inserted-text: #aff5b4;
  --color-prettylights-syntax-markup-inserted-bg: #033a16;
  --color-prettylights-syntax-markup-changed-text: #ffdfb6;
  --color-prettylights-syntax-markup-changed-bg: #5a1e02;
  --color-prettylights-syntax-markup-ignored-text: #f0f6fc;
  --color-prettylights-syntax-markup-ignored-bg: #1158c7;
  --color-prettylights-syntax-meta-diff-range: #d2a8ff;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #3d444d;
  --shadow-inset: inset 0px 1px 0px 0px #0104093d;
  --shadow-resting-xsmall: 0px 1px 0px 0px #010409cc;
  --shadow-resting-small: 0px 1px 0px 0px #01040966;
  --shadow-resting-medium: 0px 3px 6px 0px #010409cc;
  --shadow-floating-small: 0px 0px 0px 1px #3d444d, 0px 6px 12px -3px #01040966, 0px 6px 18px 0px #01040966;
  --shadow-floating-medium: 0px 0px 0px 1px #3d444d, 0px 8px 16px -4px #01040966, 0px 4px 32px -4px #01040966, 0px 24px 48px -12px #01040966, 0px 48px 96px -24px #01040966;
  --shadow-floating-large: 0px 0px 0px 1px #3d444d, 0px 24px 48px 0px #010409;
  --shadow-floating-xlarge: 0px 0px 0px 1px #3d444d, 0px 32px 64px 0px #010409;
  --shadow-floating-legacy: 0px 6px 12px -3px #01040966, 0px 6px 18px 0px #01040966;
  --outline-focus: #1f6feb solid 2px;
}
@media (prefers-color-scheme: dark) {
  [data-color-mode="auto"][data-dark-theme="dark"],
  [data-color-mode="auto"][data-dark-theme="dark"] ::backdrop {
    --topicTag-borderColor: #00000000;
    --highlight-neutral-bgColor: #d2992266;
    --page-header-bgColor: #0d1117;
    --diffBlob-addition-fgColor-text: #f0f6fc;
    --diffBlob-addition-fgColor-num: #f0f6fc;
    --diffBlob-addition-bgColor-num: #3fb9504d;
    --diffBlob-addition-bgColor-line: #2ea04326;
    --diffBlob-addition-bgColor-word: #2ea04366;
    --diffBlob-deletion-fgColor-text: #f0f6fc;
    --diffBlob-deletion-fgColor-num: #f0f6fc;
    --diffBlob-deletion-bgColor-num: #f851494d;
    --diffBlob-deletion-bgColor-line: #f8514926;
    --diffBlob-deletion-bgColor-word: #f8514966;
    --diffBlob-hunk-bgColor-num: #388bfd66;
    --diffBlob-expander-iconColor: #9198a1;
    --codeMirror-fgColor: #f0f6fc;
    --codeMirror-bgColor: #0d1117;
    --codeMirror-gutters-bgColor: #0d1117;
    --codeMirror-gutterMarker-fgColor-default: #0d1117;
    --codeMirror-gutterMarker-fgColor-muted: #9198a1;
    --codeMirror-lineNumber-fgColor: #9198a1;
    --codeMirror-cursor-fgColor: #f0f6fc;
    --codeMirror-selection-bgColor: #388bfd66;
    --codeMirror-activeline-bgColor: #656c7633;
    --codeMirror-matchingBracket-fgColor: #f0f6fc;
    --codeMirror-lines-bgColor: #0d1117;
    --codeMirror-syntax-fgColor-comment: #656c76;
    --codeMirror-syntax-fgColor-constant: #79c0ff;
    --codeMirror-syntax-fgColor-entity: #d2a8ff;
    --codeMirror-syntax-fgColor-keyword: #ff7b72;
    --codeMirror-syntax-fgColor-storage: #ff7b72;
    --codeMirror-syntax-fgColor-string: #a5d6ff;
    --codeMirror-syntax-fgColor-support: #79c0ff;
    --codeMirror-syntax-fgColor-variable: #ffa657;
    --header-fgColor-default: #ffffffb3;
    --header-fgColor-logo: #f0f6fc;
    --header-bgColor: #151b23f2;
    --header-borderColor-divider: #656c76;
    --headerSearch-bgColor: #0d1117;
    --headerSearch-borderColor: #2a313c;
    --data-blue-color-emphasis: #0576ff;
    --data-blue-color-muted: #001a47;
    --data-auburn-color-emphasis: #a86f6b;
    --data-auburn-color-muted: #271817;
    --data-orange-color-emphasis: #984b10;
    --data-orange-color-muted: #311708;
    --data-yellow-color-emphasis: #895906;
    --data-yellow-color-muted: #2e1a00;
    --data-green-color-emphasis: #2f6f37;
    --data-green-color-muted: #122117;
    --data-teal-color-emphasis: #106c70;
    --data-teal-color-muted: #041f25;
    --data-purple-color-emphasis: #975bf1;
    --data-purple-color-muted: #211047;
    --data-pink-color-emphasis: #d34591;
    --data-pink-color-muted: #2d1524;
    --data-red-color-emphasis: #eb3342;
    --data-red-color-muted: #3c0614;
    --data-gray-color-emphasis: #576270;
    --data-gray-color-muted: #1c1c1c;
    --display-blue-bgColor-muted: #001a47;
    --display-blue-bgColor-emphasis: #005bd1;
    --display-blue-fgColor: #4da0ff;
    --display-blue-borderColor-muted: #002766;
    --display-blue-borderColor-emphasis: #0576ff;
    --display-green-bgColor-muted: #122117;
    --display-green-bgColor-emphasis: #2f6f37;
    --display-green-fgColor: #41b445;
    --display-green-borderColor-muted: #182f1f;
    --display-green-borderColor-emphasis: #388f3f;
    --display-orange-bgColor-muted: #311708;
    --display-orange-bgColor-emphasis: #984b10;
    --display-orange-fgColor: #ed8326;
    --display-orange-borderColor-muted: #43200a;
    --display-orange-borderColor-emphasis: #c46212;
    --display-purple-bgColor-muted: #211047;
    --display-purple-bgColor-emphasis: #7730e8;
    --display-purple-fgColor: #b687f7;
    --display-purple-borderColor-muted: #31146b;
    --display-purple-borderColor-emphasis: #975bf1;
    --display-plum-bgColor-muted: #2a0e3f;
    --display-plum-bgColor-emphasis: #9518d8;
    --display-plum-fgColor: #d07ef7;
    --display-plum-borderColor-muted: #40125e;
    --display-plum-borderColor-emphasis: #b643ef;
    --display-red-bgColor-muted: #3c0614;
    --display-red-bgColor-emphasis: #c31328;
    --display-red-fgColor: #f27d83;
    --display-red-borderColor-muted: #58091a;
    --display-red-borderColor-emphasis: #eb3342;
    --display-coral-bgColor-muted: #3c0614;
    --display-coral-bgColor-emphasis: #c31328;
    --display-coral-fgColor: #f27d83;
    --display-coral-borderColor-muted: #58091a;
    --display-coral-borderColor-emphasis: #eb3342;
    --display-yellow-bgColor-muted: #2e1a00;
    --display-yellow-bgColor-emphasis: #895906;
    --display-yellow-fgColor: #d3910d;
    --display-yellow-borderColor-muted: #3d2401;
    --display-yellow-borderColor-emphasis: #aa7109;
    --display-gray-bgColor-muted: #1c1c1c;
    --display-gray-bgColor-emphasis: #576270;
    --display-gray-fgColor: #92a1b5;
    --display-gray-borderColor-muted: #2a2b2d;
    --display-gray-borderColor-emphasis: #6e7f96;
    --display-auburn-bgColor-muted: #271817;
    --display-auburn-bgColor-emphasis: #87534f;
    --display-auburn-fgColor: #bf9592;
    --display-auburn-borderColor-muted: #3a2422;
    --display-auburn-borderColor-emphasis: #a86f6b;
    --display-brown-bgColor-muted: #241c14;
    --display-brown-bgColor-emphasis: #755e3e;
    --display-brown-fgColor: #b69a6d;
    --display-brown-borderColor-muted: #342a1d;
    --display-brown-borderColor-emphasis: #94774c;
    --display-lemon-bgColor-muted: #291d00;
    --display-lemon-bgColor-emphasis: #786008;
    --display-lemon-fgColor: #ba9b12;
    --display-lemon-borderColor-muted: #372901;
    --display-lemon-borderColor-emphasis: #977b0c;
    --display-olive-bgColor-muted: #171e0b;
    --display-olive-bgColor-emphasis: #5e681d;
    --display-olive-fgColor: #a2a626;
    --display-olive-borderColor-muted: #252d10;
    --display-olive-borderColor-emphasis: #7a8321;
    --display-lime-bgColor-muted: #141f0f;
    --display-lime-bgColor-emphasis: #496c28;
    --display-lime-fgColor: #7dae37;
    --display-lime-borderColor-muted: #1f3116;
    --display-lime-borderColor-emphasis: #5f892f;
    --display-pine-bgColor-muted: #082119;
    --display-pine-bgColor-emphasis: #14714c;
    --display-pine-fgColor: #1bb673;
    --display-pine-borderColor-muted: #0b3224;
    --display-pine-borderColor-emphasis: #18915e;
    --display-teal-bgColor-muted: #041f25;
    --display-teal-bgColor-emphasis: #106c70;
    --display-teal-fgColor: #1cb0ab;
    --display-teal-borderColor-muted: #073036;
    --display-teal-borderColor-emphasis: #158a8a;
    --display-cyan-bgColor-muted: #001f29;
    --display-cyan-bgColor-emphasis: #036a8c;
    --display-cyan-fgColor: #07ace4;
    --display-cyan-borderColor-muted: #002e3d;
    --display-cyan-borderColor-emphasis: #0587b3;
    --display-indigo-bgColor-muted: #1b183f;
    --display-indigo-bgColor-emphasis: #514ed4;
    --display-indigo-fgColor: #9899ec;
    --display-indigo-borderColor-muted: #25215f;
    --display-indigo-borderColor-emphasis: #7070e1;
    --display-pink-bgColor-muted: #2d1524;
    --display-pink-bgColor-emphasis: #ac2f74;
    --display-pink-fgColor: #e57bb2;
    --display-pink-borderColor-muted: #451c35;
    --display-pink-borderColor-emphasis: #d34591;
    --avatar-bgColor: #ffffff1a;
    --avatar-borderColor: #ffffff26;
    --avatar-shadow: 0px 0px 0px 2px #0d1117;
    --avatarStack-fade-bgColor-default: #3d444d;
    --avatarStack-fade-bgColor-muted: #2a313c;
    --control-bgColor-rest: #212830;
    --control-bgColor-hover: #262c36;
    --control-bgColor-active: #2a313c;
    --control-bgColor-disabled: #212830;
    --control-bgColor-selected: #212830;
    --control-fgColor-rest: #f0f6fc;
    --control-fgColor-placeholder: #9198a1;
    --control-fgColor-disabled: #656c7699;
    --control-borderColor-rest: #3d444d;
    --control-borderColor-emphasis: #656c76;
    --control-borderColor-disabled: #656c761a;
    --control-borderColor-selected: #f0f6fc;
    --control-borderColor-success: #238636;
    --control-borderColor-danger: #da3633;
    --control-borderColor-warning: #9e6a03;
    --control-iconColor-rest: #9198a1;
    --control-transparent-bgColor-rest: #00000000;
    --control-transparent-bgColor-hover: #656c7633;
    --control-transparent-bgColor-active: #656c7640;
    --control-transparent-bgColor-disabled: #212830;
    --control-transparent-bgColor-selected: #656c761a;
    --control-transparent-borderColor-rest: #00000000;
    --control-transparent-borderColor-hover: #00000000;
    --control-transparent-borderColor-active: #00000000;
    --control-danger-fgColor-rest: #f85149;
    --control-danger-fgColor-hover: #ff7b72;
    --control-danger-bgColor-hover: #f851491a;
    --control-danger-bgColor-active: #f8514966;
    --control-checked-bgColor-rest: #1f6feb;
    --control-checked-bgColor-hover: #2a7aef;
    --control-checked-bgColor-active: #3685f3;
    --control-checked-bgColor-disabled: #656c7699;
    --control-checked-fgColor-rest: #ffffff;
    --control-checked-fgColor-disabled: #010409;
    --control-checked-borderColor-rest: #1f6feb;
    --control-checked-borderColor-hover: #2a7aef;
    --control-checked-borderColor-active: #3685f3;
    --control-checked-borderColor-disabled: #656c7699;
    --controlTrack-bgColor-rest: #262c36;
    --controlTrack-bgColor-hover: #2a313c;
    --controlTrack-bgColor-active: #2f3742;
    --controlTrack-bgColor-disabled: #656c7699;
    --controlTrack-fgColor-rest: #9198a1;
    --controlTrack-fgColor-disabled: #ffffff;
    --controlTrack-borderColor-rest: #3d444d;
    --controlTrack-borderColor-disabled: #656c7699;
    --controlKnob-bgColor-rest: #010409;
    --controlKnob-bgColor-disabled: #212830;
    --controlKnob-bgColor-checked: #ffffff;
    --controlKnob-borderColor-rest: #656c76;
    --controlKnob-borderColor-disabled: #212830;
    --controlKnob-borderColor-checked: #1f6feb;
    --counter-borderColor: #00000000;
    --counter-bgColor-muted: #656c7633;
    --counter-bgColor-emphasis: #656c76;
    --button-default-fgColor-rest: #f0f6fc;
    --button-default-bgColor-rest: #212830;
    --button-default-bgColor-hover: #262c36;
    --button-default-bgColor-active: #2a313c;
    --button-default-bgColor-selected: #2a313c;
    --button-default-bgColor-disabled: #212830;
    --button-default-borderColor-rest: #3d444d;
    --button-default-borderColor-hover: #3d444d;
    --button-default-borderColor-active: #3d444d;
    --button-default-borderColor-disabled: #656c761a;
    --button-default-shadow-resting: 0px 0px 0px 0px #000000;
    --button-primary-fgColor-rest: #ffffff;
    --button-primary-fgColor-disabled: #ffffff66;
    --button-primary-iconColor-rest: #ffffff;
    --button-primary-bgColor-rest: #238636;
    --button-primary-bgColor-hover: #29903b;
    --button-primary-bgColor-active: #2e9a40;
    --button-primary-bgColor-disabled: #105823;
    --button-primary-borderColor-rest: #ffffff1a;
    --button-primary-borderColor-hover: #ffffff1a;
    --button-primary-borderColor-active: #ffffff1a;
    --button-primary-borderColor-disabled: #105823;
    --button-primary-shadow-selected: 0px 0px 0px 0px #000000;
    --button-invisible-fgColor-rest: #f0f6fc;
    --button-invisible-fgColor-hover: #f0f6fc;
    --button-invisible-fgColor-disabled: #656c7699;
    --button-invisible-iconColor-rest: #9198a1;
    --button-invisible-iconColor-hover: #9198a1;
    --button-invisible-iconColor-disabled: #656c7699;
    --button-invisible-bgColor-rest: #00000000;
    --button-invisible-bgColor-hover: #656c7633;
    --button-invisible-bgColor-active: #656c7640;
    --button-invisible-bgColor-disabled: #212830;
    --button-invisible-borderColor-rest: #00000000;
    --button-invisible-borderColor-hover: #00000000;
    --button-invisible-borderColor-disabled: #656c761a;
    --button-outline-fgColor-rest: #388bfd;
    --button-outline-fgColor-hover: #58a6ff;
    --button-outline-fgColor-active: #ffffff;
    --button-outline-fgColor-disabled: #4493f880;
    --button-outline-bgColor-rest: #f0f6fc;
    --button-outline-bgColor-hover: #262c36;
    --button-outline-bgColor-active: #0d419d;
    --button-outline-bgColor-disabled: #212830;
    --button-outline-borderColor-hover: #3d444d;
    --button-outline-borderColor-selected: #3d444d;
    --button-outline-shadow-selected: 0px 0px 0px 0px #000000;
    --button-danger-fgColor-rest: #fa5e55;
    --button-danger-fgColor-hover: #ffffff;
    --button-danger-fgColor-active: #ffffff;
    --button-danger-fgColor-disabled: #f8514980;
    --button-danger-iconColor-rest: #fa5e55;
    --button-danger-iconColor-hover: #ffffff;
    --button-danger-bgColor-rest: #212830;
    --button-danger-bgColor-hover: #b62324;
    --button-danger-bgColor-active: #d03533;
    --button-danger-bgColor-disabled: #212830;
    --button-danger-borderColor-rest: #3d444d;
    --button-danger-borderColor-hover: #ffffff1a;
    --button-danger-borderColor-active: #ffffff1a;
    --button-danger-shadow-selected: 0px 0px 0px 0px #000000;
    --button-inactive-fgColor: #9198a1;
    --button-inactive-bgColor: #262c36;
    --button-star-iconColor: #e3b341;
    --buttonCounter-default-bgColor-rest: #2f3742;
    --buttonCounter-invisible-bgColor-rest: #656c7633;
    --buttonCounter-primary-bgColor-rest: #04260f33;
    --buttonCounter-outline-bgColor-rest: #051d4d33;
    --buttonCounter-outline-bgColor-hover: #051d4d33;
    --buttonCounter-outline-bgColor-disabled: #1f6feb0d;
    --buttonCounter-outline-fgColor-rest: #388bfd;
    --buttonCounter-outline-fgColor-hover: #58a6ff;
    --buttonCounter-outline-fgColor-disabled: #4493f880;
    --buttonCounter-danger-bgColor-hover: #ffffff33;
    --buttonCounter-danger-bgColor-disabled: #da36330d;
    --buttonCounter-danger-bgColor-rest: #49020233;
    --buttonCounter-danger-fgColor-rest: #f85149;
    --buttonCounter-danger-fgColor-hover: #ffffff;
    --buttonCounter-danger-fgColor-disabled: #f8514980;
    --reactionButton-selected-bgColor-rest: #388bfd33;
    --reactionButton-selected-bgColor-hover: #3a8cfd5c;
    --reactionButton-selected-fgColor-rest: #4493f8;
    --reactionButton-selected-fgColor-hover: #79c0ff;
    --focus-outlineColor: #1f6feb;
    --menu-bgColor-active: #151b23;
    --overlay-bgColor: #151b23;
    --overlay-borderColor: #3d444db3;
    --overlay-backdrop-bgColor: #21283066;
    --selectMenu-borderColor: #3d444d;
    --selectMenu-bgColor-active: #0c2d6b;
    --sideNav-bgColor-selected: #212830;
    --skeletonLoader-bgColor: #151b23;
    --timelineBadge-bgColor: #212830;
    --treeViewItem-leadingVisual-iconColor-rest: #9198a1;
    --underlineNav-borderColor-active: #f78166;
    --underlineNav-borderColor-hover: #3d444db3;
    --underlineNav-iconColor-rest: #9198a1;
    --selection-bgColor: #1f6febb3;
    --card-bgColor: #151b23;
    --label-green-bgColor-rest: #122117;
    --label-green-bgColor-hover: #182f1f;
    --label-green-bgColor-active: #214529;
    --label-green-fgColor-rest: #41b445;
    --label-green-fgColor-hover: #46c144;
    --label-green-fgColor-active: #75d36f;
    --label-orange-bgColor-rest: #311708;
    --label-orange-bgColor-hover: #43200a;
    --label-orange-bgColor-active: #632f0d;
    --label-orange-fgColor-rest: #ed8326;
    --label-orange-fgColor-hover: #f1933b;
    --label-orange-fgColor-active: #f6b06a;
    --label-purple-bgColor-rest: #211047;
    --label-purple-bgColor-hover: #31146b;
    --label-purple-bgColor-active: #481a9e;
    --label-purple-fgColor-rest: #b687f7;
    --label-purple-fgColor-hover: #c398fb;
    --label-purple-fgColor-active: #d2affd;
    --label-red-bgColor-rest: #3c0614;
    --label-red-bgColor-hover: #58091a;
    --label-red-bgColor-active: #790c20;
    --label-red-fgColor-rest: #f27d83;
    --label-red-fgColor-hover: #f48b8d;
    --label-red-fgColor-active: #f7adab;
    --label-yellow-bgColor-rest: #2e1a00;
    --label-yellow-bgColor-hover: #3d2401;
    --label-yellow-bgColor-active: #5a3702;
    --label-yellow-fgColor-rest: #d3910d;
    --label-yellow-fgColor-hover: #df9e11;
    --label-yellow-fgColor-active: #edb431;
    --label-gray-bgColor-rest: #1c1c1c;
    --label-gray-bgColor-hover: #2a2b2d;
    --label-gray-bgColor-active: #393d41;
    --label-gray-fgColor-rest: #92a1b5;
    --label-gray-fgColor-hover: #9babbf;
    --label-gray-fgColor-active: #b3c0d1;
    --label-auburn-bgColor-rest: #271817;
    --label-auburn-bgColor-hover: #3a2422;
    --label-auburn-bgColor-active: #543331;
    --label-auburn-fgColor-rest: #bf9592;
    --label-auburn-fgColor-hover: #c6a19f;
    --label-auburn-fgColor-active: #d4b7b5;
    --label-brown-bgColor-rest: #241c14;
    --label-brown-bgColor-hover: #342a1d;
    --label-brown-bgColor-active: #483a28;
    --label-brown-fgColor-rest: #b69a6d;
    --label-brown-fgColor-hover: #bfa77d;
    --label-brown-fgColor-active: #cdbb98;
    --label-lemon-bgColor-rest: #291d00;
    --label-lemon-bgColor-hover: #372901;
    --label-lemon-bgColor-active: #4f3c02;
    --label-lemon-fgColor-rest: #ba9b12;
    --label-lemon-fgColor-hover: #c4a717;
    --label-lemon-fgColor-active: #d7bc1d;
    --label-olive-bgColor-rest: #171e0b;
    --label-olive-bgColor-hover: #252d10;
    --label-olive-bgColor-active: #374115;
    --label-olive-fgColor-rest: #a2a626;
    --label-olive-fgColor-hover: #b2af24;
    --label-olive-fgColor-active: #cbc025;
    --label-lime-bgColor-rest: #141f0f;
    --label-lime-bgColor-hover: #1f3116;
    --label-lime-bgColor-active: #2c441d;
    --label-lime-fgColor-rest: #7dae37;
    --label-lime-fgColor-hover: #89ba36;
    --label-lime-fgColor-active: #9fcc3e;
    --label-pine-bgColor-rest: #082119;
    --label-pine-bgColor-hover: #0b3224;
    --label-pine-bgColor-active: #0e4430;
    --label-pine-fgColor-rest: #1bb673;
    --label-pine-fgColor-hover: #1ac176;
    --label-pine-fgColor-active: #1bda81;
    --label-teal-bgColor-rest: #041f25;
    --label-teal-bgColor-hover: #073036;
    --label-teal-bgColor-active: #0a464d;
    --label-teal-fgColor-rest: #1cb0ab;
    --label-teal-fgColor-hover: #1fbdb2;
    --label-teal-fgColor-active: #24d6c4;
    --label-cyan-bgColor-rest: #001f29;
    --label-cyan-bgColor-hover: #002e3d;
    --label-cyan-bgColor-active: #014156;
    --label-cyan-fgColor-rest: #07ace4;
    --label-cyan-fgColor-hover: #09b7f1;
    --label-cyan-fgColor-active: #45cbf7;
    --label-indigo-bgColor-rest: #1b183f;
    --label-indigo-bgColor-hover: #25215f;
    --label-indigo-bgColor-active: #312c90;
    --label-indigo-fgColor-rest: #9899ec;
    --label-indigo-fgColor-hover: #a2a5f1;
    --label-indigo-fgColor-active: #b7baf6;
    --label-blue-bgColor-rest: #001a47;
    --label-blue-bgColor-hover: #002766;
    --label-blue-bgColor-active: #00378a;
    --label-blue-fgColor-rest: #4da0ff;
    --label-blue-fgColor-hover: #61adff;
    --label-blue-fgColor-active: #85c2ff;
    --label-plum-bgColor-rest: #2a0e3f;
    --label-plum-bgColor-hover: #40125e;
    --label-plum-bgColor-active: #5c1688;
    --label-plum-fgColor-rest: #d07ef7;
    --label-plum-fgColor-hover: #d889fa;
    --label-plum-fgColor-active: #e4a5fd;
    --label-pink-bgColor-rest: #2d1524;
    --label-pink-bgColor-hover: #451c35;
    --label-pink-bgColor-active: #65244a;
    --label-pink-fgColor-rest: #e57bb2;
    --label-pink-fgColor-hover: #ec8dbd;
    --label-pink-fgColor-active: #f4a9cd;
    --label-coral-bgColor-rest: #351008;
    --label-coral-bgColor-hover: #51180b;
    --label-coral-bgColor-active: #72220d;
    --label-coral-fgColor-rest: #f7794b;
    --label-coral-fgColor-hover: #fa8c61;
    --label-coral-fgColor-active: #fdaa86;
    --tooltip-bgColor: #3d444d;
    --tooltip-fgColor: #ffffff;
    --fgColor-default: #f0f6fc;
    --fgColor-muted: #9198a1;
    --fgColor-onEmphasis: #ffffff;
    --fgColor-onInverse: #010409;
    --fgColor-white: #ffffff;
    --fgColor-black: #010409;
    --fgColor-disabled: #656c7699;
    --fgColor-link: #4493f8;
    --fgColor-neutral: #9198a1;
    --fgColor-accent: #4493f8;
    --fgColor-success: #3fb950;
    --fgColor-open: #3fb950;
    --fgColor-attention: #d29922;
    --fgColor-severe: #db6d28;
    --fgColor-danger: #f85149;
    --fgColor-closed: #f85149;
    --fgColor-done: #ab7df8;
    --fgColor-upsell: #ab7df8;
    --fgColor-sponsors: #db61a2;
    --bgColor-default: #0d1117;
    --bgColor-muted: #151b23;
    --bgColor-inset: #010409;
    --bgColor-emphasis: #3d444d;
    --bgColor-inverse: #ffffff;
    --bgColor-white: #ffffff;
    --bgColor-black: #010409;
    --bgColor-disabled: #212830;
    --bgColor-transparent: #00000000;
    --bgColor-neutral-muted: #656c7633;
    --bgColor-neutral-emphasis: #656c76;
    --bgColor-accent-muted: #388bfd1a;
    --bgColor-accent-emphasis: #1f6feb;
    --bgColor-success-muted: #2ea04326;
    --bgColor-success-emphasis: #238636;
    --bgColor-open-muted: #2ea04326;
    --bgColor-open-emphasis: #238636;
    --bgColor-attention-muted: #bb800926;
    --bgColor-attention-emphasis: #9e6a03;
    --bgColor-severe-muted: #db6d281a;
    --bgColor-severe-emphasis: #bd561d;
    --bgColor-danger-muted: #f851491a;
    --bgColor-danger-emphasis: #da3633;
    --bgColor-closed-muted: #f851491a;
    --bgColor-closed-emphasis: #da3633;
    --bgColor-done-muted: #ab7df826;
    --bgColor-done-emphasis: #8957e5;
    --bgColor-upsell-muted: #ab7df826;
    --bgColor-upsell-emphasis: #8957e5;
    --bgColor-sponsors-muted: #db61a21a;
    --bgColor-sponsors-emphasis: #bf4b8a;
    --borderColor-default: #3d444d;
    --borderColor-muted: #3d444db3;
    --borderColor-emphasis: #656c76;
    --borderColor-disabled: #656c761a;
    --borderColor-transparent: #00000000;
    --borderColor-translucent: #ffffff26;
    --borderColor-neutral-muted: #3d444db3;
    --borderColor-neutral-emphasis: #656c76;
    --borderColor-accent-muted: #388bfd66;
    --borderColor-accent-emphasis: #1f6feb;
    --borderColor-success-muted: #2ea04366;
    --borderColor-success-emphasis: #238636;
    --borderColor-open-muted: #2ea04366;
    --borderColor-open-emphasis: #238636;
    --borderColor-attention-muted: #bb800966;
    --borderColor-attention-emphasis: #9e6a03;
    --borderColor-severe-muted: #db6d2866;
    --borderColor-severe-emphasis: #bd561d;
    --borderColor-danger-muted: #f8514966;
    --borderColor-danger-emphasis: #da3633;
    --borderColor-closed-muted: #f8514966;
    --borderColor-closed-emphasis: #da3633;
    --borderColor-done-muted: #ab7df866;
    --borderColor-done-emphasis: #8957e5;
    --borderColor-upsell-muted: #ab7df866;
    --borderColor-upsell-emphasis: #8957e5;
    --borderColor-sponsors-muted: #db61a266;
    --borderColor-sponsors-emphasis: #bf4b8a;
    --color-ansi-black: #2f3742;
    --color-ansi-black-bright: #656c76;
    --color-ansi-white: #f0f6fc;
    --color-ansi-white-bright: #ffffff;
    --color-ansi-gray: #656c76;
    --color-ansi-red: #ff7b72;
    --color-ansi-red-bright: #ffa198;
    --color-ansi-green: #3fb950;
    --color-ansi-green-bright: #56d364;
    --color-ansi-yellow: #d29922;
    --color-ansi-yellow-bright: #e3b341;
    --color-ansi-blue: #58a6ff;
    --color-ansi-blue-bright: #79c0ff;
    --color-ansi-magenta: #be8fff;
    --color-ansi-magenta-bright: #d2a8ff;
    --color-ansi-cyan: #39c5cf;
    --color-ansi-cyan-bright: #56d4dd;
    --color-prettylights-syntax-comment: #9198a1;
    --color-prettylights-syntax-constant: #79c0ff;
    --color-prettylights-syntax-constant-other-reference-link: #a5d6ff;
    --color-prettylights-syntax-entity: #d2a8ff;
    --color-prettylights-syntax-storage-modifier-import: #f0f6fc;
    --color-prettylights-syntax-entity-tag: #7ee787;
    --color-prettylights-syntax-keyword: #ff7b72;
    --color-prettylights-syntax-string: #a5d6ff;
    --color-prettylights-syntax-variable: #ffa657;
    --color-prettylights-syntax-brackethighlighter-unmatched: #f85149;
    --color-prettylights-syntax-brackethighlighter-angle: #9198a1;
    --color-prettylights-syntax-invalid-illegal-text: #f0f6fc;
    --color-prettylights-syntax-invalid-illegal-bg: #8e1519;
    --color-prettylights-syntax-carriage-return-text: #f0f6fc;
    --color-prettylights-syntax-carriage-return-bg: #b62324;
    --color-prettylights-syntax-string-regexp: #7ee787;
    --color-prettylights-syntax-markup-list: #f2cc60;
    --color-prettylights-syntax-markup-heading: #1f6feb;
    --color-prettylights-syntax-markup-italic: #f0f6fc;
    --color-prettylights-syntax-markup-bold: #f0f6fc;
    --color-prettylights-syntax-markup-deleted-text: #ffdcd7;
    --color-prettylights-syntax-markup-deleted-bg: #67060c;
    --color-prettylights-syntax-markup-inserted-text: #aff5b4;
    --color-prettylights-syntax-markup-inserted-bg: #033a16;
    --color-prettylights-syntax-markup-changed-text: #ffdfb6;
    --color-prettylights-syntax-markup-changed-bg: #5a1e02;
    --color-prettylights-syntax-markup-ignored-text: #f0f6fc;
    --color-prettylights-syntax-markup-ignored-bg: #1158c7;
    --color-prettylights-syntax-meta-diff-range: #d2a8ff;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #3d444d;
    --shadow-inset: inset 0px 1px 0px 0px #0104093d;
    --shadow-resting-xsmall: 0px 1px 0px 0px #010409cc;
    --shadow-resting-small: 0px 1px 0px 0px #01040966;
    --shadow-resting-medium: 0px 3px 6px 0px #010409cc;
    --shadow-floating-small: 0px 0px 0px 1px #3d444d, 0px 6px 12px -3px #01040966, 0px 6px 18px 0px #01040966;
    --shadow-floating-medium: 0px 0px 0px 1px #3d444d, 0px 8px 16px -4px #01040966, 0px 4px 32px -4px #01040966, 0px 24px 48px -12px #01040966, 0px 48px 96px -24px #01040966;
    --shadow-floating-large: 0px 0px 0px 1px #3d444d, 0px 24px 48px 0px #010409;
    --shadow-floating-xlarge: 0px 0px 0px 1px #3d444d, 0px 32px 64px 0px #010409;
    --shadow-floating-legacy: 0px 6px 12px -3px #01040966, 0px 6px 18px 0px #01040966;
    --outline-focus: #1f6feb solid 2px;
  }
}
